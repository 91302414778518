import * as React from 'react';
import { Link } from 'gatsby';

const Contact = () => (
  <Link
    to="/contact/"
    className="text-white inline-block w-full md:w-auto text-center bg-contrast hover:bg-contrast-dark py-2 px-4 rounded mt-2 md:mt-0 md:ml-1 transition ease-in-out duration-500"
  >
    Book Now
  </Link>
);

export default Contact;
