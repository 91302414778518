import * as React from 'react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

import Header from './header/header';
import Footer from './footer/footer';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <div className="bg-primary">
        <main className="lg:max-w-screen-xl 2xl:border-l-4 2xl:border-r-4 2xl:border-contrast mx-auto">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
