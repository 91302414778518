import * as React from 'react';
import { Link } from 'gatsby';

import ContactLink from './contactLink';

type Pages = '/' | '/about/' | '/learn-guitar/' | '/gift-vouchers/';
type LinkLabels = 'Home' | 'About' | 'Learn Guitar' | 'Gift Vouchers';

const NavLink = ({ to, label }: { to: Pages; label: LinkLabels }) => (
  <Link
    to={to}
    activeClassName="active-link"
    className="block md:inline-block p-2 border-b-2 md:border-none hover:text-contrast"
  >
    {label}
  </Link>
);

const NavMenu = ({ display }: { display: 'hidden' | 'block' }) => (
  <div
    className={`${display} md:flex items-center w-full md:w-auto text-right text-semibold mt-5 md:mt-0 border-t-2 md:border-none lg:ml-auto`}
  >
    <NavLink to="/" label="Home" />
    <NavLink to="/about/" label="About" />
    <NavLink to="/learn-guitar/" label="Learn Guitar" />
    <NavLink to="/gift-vouchers/" label="Gift Vouchers" />
    <ContactLink />
  </div>
);

export default NavMenu;
