import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaInstagram, FaYoutube } from 'react-icons/fa';

const Socials = () => (
  <div className="flex flex-col items-center mb-2">
    <span>Connect with Jonny Ball</span>
    <div className="flex">
      <a aria-label="Jonny Ball Guitar Instagram" className="mx-1" href="https://www.instagram.com/jonnyballguitar">
        <FaInstagram />
      </a>
      <a
        aria-label="Jonny Ball Guitar YouTube"
        className="mx-1"
        href="https://www.youtube.com/channel/UCEqRE9a-ZjROzYYCTOsQhdw"
      >
        <FaYoutube />
      </a>
    </div>
  </div>
);

const Copyright = () => (
  <div className="text-sm">
    <p>© {new Date().getFullYear()}, Jonny Ball Music </p>
  </div>
);

const Designer = () => (
  <div className="md:self-end">
    <p className="text-xs text-center">
      Built with <a href="https://www.gatsbyjs.com">Gatsby</a> by <a href="https://www.danbridges.co.uk">Dan Bridges</a>
    </p>
  </div>
);

const Footer = () => (
  <footer className="bg-primary text-white border-t-4 border-contrast">
    <div className="mx-auto flex flex-col md:flex-row items-center md:justify-between py-2 md:py-5 px-2 max-w-screen-xl">
      <div className="mb-2 md:mb-0">
        <StaticImage
          src="../../../images/logos/jb-icon-white.png"
          layout="constrained"
          width={75}
          quality={100}
          placeholder="blurred"
          formats={['auto', 'webp', 'avif']}
          alt="Jonny Ball logo"
        />
      </div>
      <div className="text-center mb-2 md:mb-0">
        {/* <Socials /> */}
        <Copyright />
      </div>
      <Designer />
    </div>
  </footer>
);

export default Footer;
