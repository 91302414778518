import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Logo = () => (
  <Link to="/">
    <StaticImage
      src="../../../../images/logos/jb-logo-white.png"
      layout="constrained"
      width={200}
      quality={100}
      placeholder="blurred"
      formats={['auto', 'webp', 'avif']}
      alt="Jonny Ball logo"
    />
  </Link>
);

export default Logo;
