import React, { useState } from 'react';

import { Hamburger, Logo, NavMenu } from './components/main';

const Header = () => {
  const [viewMenu, setViewMenu] = useState(false);

  return (
    <header className="bg-primary text-white border-b-4 border-contrast">
      <nav className="flex md:flex-col lg:flex-row flex-wrap items-center justify-between p-5 max-w-screen-xl mx-auto">
        <Logo />
        <Hamburger setViewMenu={setViewMenu} viewMenu={viewMenu} />
        <NavMenu display={viewMenu ? 'block' : 'hidden'} />
      </nav>
    </header>
  );
};

export default Header;
