import React, { Dispatch, SetStateAction } from 'react';

type IDisplay = 'hidden' | 'block';

const MenuSVG = ({ display }: { display: IDisplay }) => {
  return (
    <svg
      className={`h-6 w-6 text-white ${display}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  );
};

const CloseMenuSVG = ({ display }: { display: IDisplay }) => {
  return (
    <svg
      className={`h-6 w-6 text-white ${display}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
};

const Hamburger = ({
  setViewMenu,
  viewMenu,
}: {
  setViewMenu: Dispatch<SetStateAction<boolean>>;
  viewMenu: boolean;
}) => {
  return (
    <div className="flex h-6 w-6 md:hidden cursor-pointer">
      <button id="hamburger" type="button" aria-label="menu button" onClick={() => setViewMenu(!viewMenu)}>
        <MenuSVG display={viewMenu ? 'hidden' : 'block'} />
        <CloseMenuSVG display={viewMenu ? 'block' : 'hidden'} />
      </button>
    </div>
  );
};

export default Hamburger;
